import "lazysizes";
import Splide from "@splidejs/splide";
import {FloatLabels} from "@keodesign/float-labels";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");

window.onload = function() {  
	setupSlideMenu();
	new FloatLabels(".js-float-wrap");
	$("#map-click").click(function(){
		// shield the map on mobile for easy scrolling
		$(this).hide();
	});

	add_menu_button_style();

	var elms = document.getElementsByClassName( "splide" );
	for ( var i = 0, len = elms.length; i < len; i++ ) {
		new Splide(elms[i], {
			type  : "fade",
			rewind: true,
			arrows: false,
		}).mount();
	}
};

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

function add_menu_button_style(){
	$("nav.menu a > span:contains('Shop')").addClass("btn-gradient btn");
	$("nav.menu a > span:contains('Portal')").addClass("btn-blue btn");

}



//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});